'use client';
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '@/lib/firebaseClient'; // Client-side Firestore instance
import SectionWrapper from '@/components/SectionWrapper';
import CustomImage from '@/components/CustomImage';
import MediaContainer from '@/components/MediaContainer';
import { servImages } from '@/constants/images';

export default function PopularServices() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, 'detailingServices'),
        );
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Filter to only include fullDetail services
        const fullDetailServices = data.filter(
          (service) => service.category === 'fullDetail',
        );

        // Sort services so that the order is: elite, deluxe, then essentials.
        // This assumes that the serviceTitle includes one of these keywords.
        const order = ['elite', 'deluxe', 'essentials'];
        const sortedServices = fullDetailServices.sort((a, b) => {
          const titleA = a.serviceTitle.toLowerCase();
          const titleB = b.serviceTitle.toLowerCase();
          const indexA = order.findIndex((orderType) =>
            titleA.includes(orderType),
          );
          const indexB = order.findIndex((orderType) =>
            titleB.includes(orderType),
          );
          return indexA - indexB;
        });

        setServices(sortedServices);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  return (
    <SectionWrapper id="popular-packages" className="py-16">
      <div className="mx-auto max-w-7xl px-4">
        <h2 className="mb-12 text-center text-3xl font-extrabold text-gray-900 md:text-4xl">
          Our Most Popular Packages
        </h2>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {services.map((service) => (
            <div
              key={service.id}
              className="from-mad-blue to-mad-red rounded-lg bg-gradient-to-r p-1 shadow-lg transition duration-300 hover:shadow-2xl"
            >
              <div className="flex h-full flex-col rounded-lg bg-white p-6">
                <div className="relative">
                  <MediaContainer aspectRatio="aspect-4/3">
                    <CustomImage
                      src={service.serviceImage}
                      alt={service.serviceTitle}
                    />
                  </MediaContainer>
                  <div className="absolute inset-0 rounded-md bg-gradient-to-t from-black via-transparent opacity-50"></div>
                </div>
                <h3 className="mt-4 text-2xl font-bold text-gray-800">
                  {service.serviceTitle}
                </h3>
                <p className="mt-2 line-clamp-3 text-gray-600">
                  {service.serviceDescription}
                </p>
                <p className="mt-2 text-gray-500 italic">{service.bestFor}</p>
                <div className="mt-4">
                  <div className="grid grid-cols-2 gap-2">
                    {['small', 'medium', 'large', 'extra large'].map(
                      (size, idx) => (
                        <div
                          key={idx}
                          className="rounded border p-2 text-center"
                        >
                          <p className="text-xs text-gray-500 capitalize">
                            {size} vehicle
                          </p>
                          <p className="text-lg font-bold text-red-600">
                            ${service.servicePricing[idx]}
                          </p>
                        </div>
                      ),
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  <a
                    href={`/dv/services/car-detailing#${service.slug}`}
                    className="bg-mad-red block rounded px-4 py-2 text-center text-white transition-colors duration-300 hover:bg-red-700"
                  >
                    Learn More &amp; Book Now
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
}
