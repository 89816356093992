import(/* webpackMode: "eager" */ "/vercel/path0/app/components/desktop/CtaSection.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/desktop/GoogleReviews.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/desktop/HeroSection.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/desktop/homepage/popularServices.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
